
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import HomeFiltersService, { HomeFiltersServiceS } from '@/modules/home/home-filters.service';
import CompsetModel from '@/modules/compsets/models/compset.model';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
// @ts-ignore
import CustomDesignSelect, { Item } from '@/modules/common/components/ui-kit/custom-design-select.vue';

@Component({
    components: { CustomDesignSelect },
})
export default class CompsetHomeVisibilityFilter extends Vue {
    @Inject(HomeFiltersServiceS) private homeFiltersService!: HomeFiltersService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;

    get compsetId() {
        return this.homeFiltersService.storeState.settings.visibilityCompsetId;
    }
    set compsetId(value) {
        if (value) {
            this.homeFiltersService.updateCompset(value);
        }
    }
    get compsetTypeItems(): Item[] {
        if (!this.compsetsService.compsets) {
            return [];
        }

        return this.compsetsService.compsets.map((compset: CompsetModel) => ({
            value: compset.id,
            name: `${compset.name} (${this.$t(`compset.${compset.type}`)})`,
        }));
    }
}
