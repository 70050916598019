
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';

import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import HomeFiltersService, { HomeFiltersServiceS } from '@/modules/home/home-filters.service';

import ASSESSMENT_TYPES from '@/modules/common/constants/assessments-types.constant';
import type Day from '@/modules/common/types/day.type';

import GraphDateLegend from '@/modules/common/components/ui-kit/graph-date-legend.vue';
import HomeService, { HomeServiceS } from '@/modules/home/home.service';
import CalendarChain, { AssessmentData } from '@/modules/common/components/ui-kit/calendar-chain.vue';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';

@Component({
    components: {
        CalendarChain,
        GraphDateLegend,
    },
})
export default class RatesCompsetChain extends Vue {
    @Inject(HomeServiceS)
    private homeService!: HomeService;

    @Inject(HomeFiltersServiceS)
    private homeFiltersService!: HomeFiltersService;

    @Inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    @Inject(CompsetsServiceS)
    private compsetsService!: CompsetsService;

    get skeleton() {
        return this.homeService.storeState.loadingRates.isLoading();
    }

    get currentDay() {
        const { month, year } = this.documentFiltersService.storeState.settings;
        const date = new Date();

        if (year === date.getFullYear() && month === date.getMonth()) {
            return date.getDate() - 1;
        } if (year > date.getFullYear() || month < date.getMonth()) {
            return 32;
        }

        return -1;
    }

    /** Returns compsets that have selected rates provider */
    get avaliableCompsets() {
        const { compsets } = this.compsetsService;
        const selectedProviderId = this.homeFiltersService.ratesProvider;
        if (compsets && selectedProviderId) {
            return compsets.filter(
                compset => compset.rateProviders.includes(selectedProviderId),
            );
        }

        return compsets;
    }

    chainTypes(compsetId: string) {
        return this.documentFiltersService.days
            .map((day: Day) => {
                const assessment = this.homeService
                    .getChainAssessment(compsetId, day);
                const colorModificator = this.getColorByAssessment(assessment || ASSESSMENT_TYPES.NO_DATA);
                const isPastDay = this.documentFiltersService.isPreviousDay(day);
                const isNA = !assessment
                    || assessment === ASSESSMENT_TYPES.NA;
                const isHaveHoliday = false;
                const isHaveLocalEvents = false;

                return {
                    type: assessment,
                    colorModificator,
                    isPastDay,
                    isNA,
                    isHaveHoliday,
                    isHaveLocalEvents,
                };
            }) as AssessmentData[];
    }

    private getColorByAssessment(assessment: ASSESSMENT_TYPES): string {
        const modificators: { [k: number]: string } = {
            [ASSESSMENT_TYPES.GOOD]: 'green',
            [ASSESSMENT_TYPES.NORMAL]: 'yellow',
            [ASSESSMENT_TYPES.BAD]: 'red',
            [ASSESSMENT_TYPES.SOLD_OUT]: 'grey',
            [ASSESSMENT_TYPES.NO_DATA]: 'white',
        };

        return modificators[assessment as number];
    }

    get compsetsLabels(): string[] {
        const { year, month } = this.documentFiltersService.storeState.settings;
        const nextCountDays = new Date(year, month + 1, 0).getDate();

        const labels = [];

        const trimmedYear = String(year).slice(2);

        labels.unshift(`${nextCountDays}/${month + 1}/${trimmedYear}`);
        labels.unshift(`25/${month + 1}/${trimmedYear}`);
        labels.unshift(`19/${month + 1}/${trimmedYear}`);
        labels.unshift(`13/${month + 1}/${trimmedYear}`);
        labels.unshift(`7/${month + 1}/${trimmedYear}`);
        labels.unshift(`1/${month + 1}/${trimmedYear}`);

        return labels;
    }
}
