
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';

import HomeFiltersService, { HomeFiltersServiceS } from '@/modules/home/home-filters.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';

import CustomDesignSelect from '@/modules/common/components/ui-kit/custom-design-select.vue';

@Component({
    components: { CustomDesignSelect },
})
export default class ProviderRatesFilter extends Vue {
    @Inject(HomeFiltersServiceS) private homeFiltersService!: HomeFiltersService;
    @Inject(ProvidersServiceS) private providersService!: ProvidersService;

    get provider(): string | null {
        return this.homeFiltersService.ratesProvider;
    }

    set provider(value: string | null) {
        if (!value) return;
        this.homeFiltersService.updateProvider(value);
    }

    public getProviderLabel(providerName: string) {
        return this.providersService.getProviderLabel(providerName);
    }

    get providerItems() {
        return this.homeFiltersService.possibleProviders
            .map(provider => ({
                value: provider!.name!,
                name: this.getProviderLabel(provider!.name!),
            }));
    }
}
