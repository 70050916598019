import { render, staticRenderFns } from "./demand-graph-component.vue?vue&type=template&id=5872bade&scoped=true&"
import script from "./demand-graph-component.vue?vue&type=script&lang=ts&"
export * from "./demand-graph-component.vue?vue&type=script&lang=ts&"
import style0 from "./demand-graph-component.vue?vue&type=style&index=0&id=5872bade&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5872bade",
  null
  
)

export default component.exports