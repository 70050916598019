
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import DemandOccupancyGraph from '@/modules/common/components/ui-kit/occupancy-demand-graph/index.vue';
import HotelHomepageService, { HotelHomepageServiceS } from '@/modules/home/march-demo/hotel-homepage.service';
import Tooltip from '@/modules/common/components/ui-kit/tooltip.vue';

@Component({
    components: { DemandOccupancyGraph, Tooltip },
})
export default class DemandGraphComponent extends Vue {
    @Inject(HotelHomepageServiceS) protected hotelHomepageService!: HotelHomepageService;

    get demandData() {
        return this.hotelHomepageService.demandsData || [];
    }

    get demandLabels() {
        return this.hotelHomepageService.demandsLabels;
    }

    get isLoading() {
        const { demandsData } = this.hotelHomepageService;

        return demandsData === null || this.hotelHomepageService.isDemandDataLoading;
    }

    public dummyData = Array(30).fill(0);
}
