
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import HomeFiltersService, { HomeFiltersServiceS } from '@/modules/home/home-filters.service';
// @ts-ignore
import CustomDesignSelect, { Item } from '@/modules/common/components/ui-kit/custom-design-select.vue';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';

@Component({
    components: { CustomDesignSelect },
})
export default class ProviderHomeFilter extends Vue {
    @Inject(HomeFiltersServiceS) private homeFiltersService!: HomeFiltersService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(ProvidersServiceS) private providersService!: ProvidersService;

    get provider() {
        return this.homeFiltersService.storeState.settings.visibilityProvider;
    }
    set provider(value) {
        const { settings } = this.homeFiltersService.storeState;
        this.homeFiltersService.saveFilters({ ...settings, visibilityProvider: value });
    }

    getProviderLabel(providerName: string) {
        const { data } = this.providersService;

        if (!data) {
            return providerName;
        }

        const provider = data.find(currentProvider => currentProvider.name === providerName);

        if (!provider) {
            return '';
        }

        return provider.label || provider.name || '';
    }

    get providerItems(): Item[] {
        const compset = this.compsetsService.getCompset(this.homeFiltersService.storeState.settings.visibilityCompsetId);

        if (!compset) {
            return [];
        }

        return compset.marketProviders.map(provider => ({
            value: provider,
            name: this.getProviderLabel(provider) as string,
        }));
    }
}
